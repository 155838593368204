body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--main)
}

::placeholder {
  font-family: 'Montserrat', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --main: #234759;
  --main-dark: #182F3C;
  --main-darker: #0c181E;
  --secondary: #3B7695;
  --secondary-light: #b0cedd;
  --secondary-light2: #9CBFD1;
  --secondary-lighter: #ECF8FF;
  --light-blue: #c3e0ec;
  --light-blue-lighter: #cbe5f0;
  --grey-darker: #3f4a59;
  --grey-dark: #858585;
  --grey: #D7E1E6;
  --grey-light: #F0F6F9;
  --background: #F5F6FA;
  --white: #FFFFFF;
  --green: #AFE052;
  --green-light: #b8e269;
  --green-dark: #abdb4c;
  --warning: #FFCC60;
  --warning-dark: rgb(252, 190, 58);
  --error: #ff6060;
  --dark-layer: rgba(32, 70, 141, 0.5);
  --accent-02: #FF015B;
  --accent-02-light: #ffcfe0;
  --import: #65d8c8;
  --import-light: #DEF7F4;
  --export: #9776e5;
  --export-light: #F2EEFC;
}

*:focus {
  outline: none;
}

::-webkit-scrollbar {
  width: 0.4rem;
}

::-webkit-scrollbar-track {
  background: var(--grey-light);
  border-radius: 25px;
}

::-webkit-scrollbar-thumb {
  background: var(--secondary-light);
  border-radius: 25px;
}